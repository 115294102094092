.sign-card {
  @include card-container;
  margin-bottom: 0;
  min-height: rem-calc(372);
  overflow: visible;
  position: relative;
  width: rem-calc(258);

  a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: get-color(royal);
      text-decoration: underline;
    }
  }

  h3 {
    margin-bottom: 3rem;
  }

  &--borderless {
    border-color: transparent;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.02);
  }

  &--official {
    .sign-card__media {
      @include responsive-embed(dictionary);
      background: #000;
    }
    .sign-card__title {
      color: $black;
    }
    h3 {
      margin-bottom: 0.25rem;
    }

    .label {
      margin-left: -4px;
    }

    .sign-card__section {
      flex: 0;
      justify-content: start;
      margin-bottom: auto;
      padding: 0 1rem;
    }
  }

  &__canonical {
    border-left: solid 2px get-color(accent);
  }

  &__canonical-link {
    color: get-color(success) !important;
  }

  &__bottom {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .card-section;
    border-top: solid 1px get-color(extra-light);
    height: rem-calc(56);
    justify-content: space-between;
    max-height: rem-calc(56);
    padding: 0 1rem;
  }

  &__text-area {
    &--notes {
      color: $medium-gray;
      font-size: 0.875rem;
      font-weight: normal;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
  }

  &__folders {
    &__button {
      align-items: center;
      background: $white;
      border-radius: 50%;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
      display: flex;
      height: 40px;
      justify-content: center;
      position: relative;
      width: 40px;

      svg {
        @include icon-fill(royal);
        @include icon-size(mid);
      }

      &--text-color {
        color: #646970 !important;
      }

      &--in-folder {
        &:not(:hover) {
          background: get-color(success-x-light);

          svg {
            @include icon-fill(success);
          }
        }
      }

      &--in-folder-for-show {
        &:hover {
          filter: brightness(75%);
        }

        svg {
          @include icon-fill(success);
        }
      }

      &:hover {
        background: rgba(4, 23, 35, 0.1);
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      // This actually means 'active' in Foundation
      &.hover {
        background: get-color(royal);

        svg {
          @include icon-fill(white);
        }
      }
    }

    &__menu {
      @include dropdown-container;
      border: solid 1px #bdc1c4;
      border-radius: 8px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
      max-height: 300px;
      overflow-y: auto;
      z-index: 500;
    }
  }

  &__divider {
    border-bottom: solid 1px $background;
    margin: 0 0 1rem;
  }

  &__section {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .card-section;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem 1rem;

    &--horizontal {
      flex-direction: row;
    }

    &--vertical {
      flex-direction: column;
      margin-top: auto;
    }
  }

  &__title {
    color: get-color(royal);
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem 0 rem-calc(4);
  }

  &__supplementary-titles {
    margin-bottom: rem-calc(4);

    &__maori,
    &__secondary {
      font-size: 1rem;

      &.small {
        font-size: 0.8rem;
      }
    }

    &__secondary {
      color: get-color(medium);

      &.small {
        color: $black;
        font-weight: normal;
      }
    }
  }

  &__media {
    @include responsive-embed;
    border-top-left-radius: $global-radius * 2;
    border-top-right-radius: $global-radius * 2;
    margin-bottom: 0;

    // See responsive-embed - we need to target the
    // sign video wrapper seperately from video, embed, iframe
    // etc.
    .video-wrapper {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    video {
      &[poster] {
        object-fit: cover;
      }
    }
  }

  &__subtitle {
    align-items: center;
    color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: rem-calc(13);
    line-height: 1.4rem;
    margin-top: 0.2rem;

    .bullet {
      font-weight: normal;
      margin-top: 0.1rem;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: rem-calc(13);
    font-weight: 600;

    &__margin {
      margin-right: 25px;
    }
  }
}

.no-js .sign-card {
  .sign-card__folders__menu:hover,
  &:active .sign-card__folders__menu,
  &:active .sign-card__folders__menu:hover {
    display: block !important;
    visibility: visible !important;
  }
}
