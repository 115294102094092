.navigation {
  @include breakpoint(medium) {
    @include flex;
    border-top: get-color(accent) solid 4px;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0 1.6rem;
  }

  &__wrapper {
    background-color: $primary-color;

    @include breakpoint(small down) {
      height: 100%;
      left: -100%;
      position: absolute;
      top: 0;
      transition: 0.5s ease;
      width: 320px;
      z-index: 10;

      &--open {
        left: 0;
      }
    }

    @include breakpoint(large) {
      background-color: $primary-color;
    }
  }

  &__list {
    @include menu-base;
    @include menu-direction(horizontal);

    @include breakpoint(medium) {
      @include menu-direction(horizontal);
      align-items: center;
      margin-bottom: 0;
      width: auto;
    }

    &.is-active {
      background: $extra-light-gray;
      padding-left: $global-padding;

      @include breakpoint(medium) {
        background: $white;
        border: $light-gray solid 1px;
        left: 0;
        margin-left: $global-margin;
        position: absolute;
        top: 100%;
        width: auto;
        z-index: 100;
      }
    }

    &--expand {
      flex: 2;
    }

    &--right {
      justify-self: right;
      margin-left: auto;

      .navigation__item {
        &:last-child {
          .navigation__list {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  &__title {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .navigation__item;

    &,
    & a {
      font-size: 1.5rem;
      font-weight: normal !important;
    }
  }

  &__item {
    @include breakpoint(medium) {
      @include flex;
      @include flex-align(left, middle);
    }

    & > a,
    & > li > a {
      color: $white !important; // Override default menu styles
      font-weight: bold !important; //Override default menu styles
      padding: 0.5rem;
      text-decoration: none;
      width: auto;

      @include breakpoint(medium) {
        padding: 1rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__button {
    &--close {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .close-button;

      @include breakpoint(medium) {
        display: none;
      }
    }

    &--toggle {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .menu-icon;
    }
  }
}

.submenu-toggle {
  outline: none;
}

.no-js {
  .navigation__list {
    .navigation__list {
      display: none;
    }
  }
}
