.sign-comments {
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625 1.5rem 0 rgba(0, 0, 0, 0.02);
  margin-top: 1.25rem;

  &__divider {
    border-bottom: solid 1px $background;
    margin-bottom: 1.75rem;
    margin-top: 1.75rem;
  }

  &--heading {
    color: $black;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 1.25rem 0 0 1.125rem;
  }

  &--total {
    color: $medium-gray;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.77;
    margin: 1.25rem 1rem 0 0;
    text-align: right;
  }

  &__video {
    height: auto;
    max-height: 15rem; // 240px;
    max-width: 20rem; // 320px;
    width: auto;
  }

  &__comment {
    background-color: get-color(extra-light);
    border-radius: 0 0.75rem 0.75rem;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &__username {
      &--link {
        color: get-color(royal);
        font-weight: bold;
        padding-right: 0.5rem;
        text-decoration: none;
      }
    }

    &__options {
      padding-top: 0.5rem;
    }
  }

  &__replies {
    margin-left: 3.5rem;

    &--reply {
      color: $medium-gray;
      font-size: 0.875rem;
      font-weight: normal;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }

    &--link {
      text-decoration: none;
    }
  }
}

.sign-comment {
  &--heading {
    font-size: 0.875rem;
    font-weight: bold;
  }

  &__reply {
    margin-right: 2rem;
    padding-bottom: 1rem;
  }

  &__checkbox {
    bottom: 1rem;
  }

  &__video {
    margin-left: 3.5rem;

    &__byte-size {
      color: $medium-gray;
      font-size: 0.875rem;
      font-weight: normal;
    }

    &__text-heading {
      color: $medium-gray;
      font-size: 0.875rem;
      font-weight: bold;
    }

    &__delete {
      float: right;
    }
  }

  &__text-area {
    &--new-reply {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 2.5rem;
    }

    &--new-comment {
      margin-left: 0.5rem;
    }

    &--text {
      border-radius: 0.75rem;
      font-size: 0.875rem;
    }
  }

  &__select {
    width: 30%;

    &__items {
      background-color: get-color(extra-light);
      border: 0;
      border-radius: 0.25rem;
      color: get-color(royal);
      font-size: 0.875rem;
      font-weight: bold;
      outline: none;
    }
  }
}
