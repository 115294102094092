.video-wrapper,
.video-wrapper--hero {
  display: flex;
  position: relative;
}

.video-wrapper--hero {
  max-height: 360px;
  overflow: hidden;
  @include breakpoint(large) {
    overflow: visible;
  }
}

.video {
  max-width: 100%;
  margin: auto;

  // Hide the controls on Chrome
  &::-webkit-media-controls {
    display: none;
  }

  &[poster] {
    object-fit: cover;
  }

  &.has-video {
    & + .video__overlay {
      display: block;
    }
  }

  &--hero {
    width: 100%;

    @include breakpoint(large) {
      left: -200px;
      max-height: 360px;
      max-width: none;
      position: absolute;
      width: auto;
      z-index: 0;

      &--large {
        display: none;
      }
    }
  }

  &__poster {
    height: 100%;
    max-width: none;
    position: absolute;
    right: 0;
    z-index: 1;

    @include breakpoint(large) {
      max-height: 360px;
      max-width: none;
      position: absolute;
      right: -10px;
      width: auto;
      z-index: 1;
    }
  }

  &--playing {
    & + .video__overlay {
      // Make sure the overlay is always hidden when it should be
      display: none !important;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.25);
    background-image: url("images/video-play.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 32px;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    &--hero {
      background-color: transparent;
      background-image: url("images/hero-play.svg");
      background-size: rem-calc(48);
      cursor: pointer;

      @include breakpoint(medium) {
        background-size: rem-calc(64);
      }
    }
  }
}
