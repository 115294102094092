.file-upload {
  background: $extra-light-gray;
  border: dashed 2px $light-gray;
  border-radius: $global-radius * 2;
  margin: 1rem auto;
  padding: 4rem 2rem;

  &,
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.file-upload--dragged-over {
  border: solid 2px $light-gray;
}
