@import url("//fonts.googleapis.com/css?family=Montserrat:400,500,500i,600,700,700i&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  &.underlined {
    &::after {
      background: map-get($foundation-palette, accent);
      border-radius: 4px;
      content: " ";
      display: block;
      height: 8px;
      margin: 1rem 0;
      width: 80px;
    }
  }

  &.normal {
    font-weight: normal;
  }

  @each $name, $color in $foundation-palette {
    &.#{$name} {
      color: $color;
    }
  }

  small {
    font-size: 1rem;
    font-weight: normal;
  }
}

a {
  font-weight: bold;
}

.no-underline {
  text-decoration: none;
}

.bullet {
  color: $black;
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font-family: "Lucida Grande";
  font-size: 13px;
  font-weight: bold;
  margin: 0 rem-calc(8);
}

small {
  font-size: rem-calc(13);
  font-weight: bold;
  line-height: 1.8;

  @each $name, $color in $foundation-palette {
    &.#{$name} {
      color: $color !important;
    }
  }
}
