label {
  margin-bottom: rem-calc(4);
  margin-top: rem-calc(24);

  &.inline {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .middle;
    line-height: 2; // Match form input
    margin-bottom: 0;
    padding-right: 1rem;
  }

  // Form spacing for repeated labels
  & + input + label {
    margin-top: 1rem;
  }
}
