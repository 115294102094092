.avatar {
  border: solid 2px $white;
  border-radius: 50%;

  // Required to avoid avatar elements inside menus getting scaled by
  // .menu li > img
  transform: none !important;

  &--small {
    height: 32px;
    width: 32px;
  }

  &--right {
    margin-left: 0.5rem;
  }

  &--left {
    margin-right: 0.5rem;
  }
}
