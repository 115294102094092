.checkbox,
.radio {
  margin-bottom: $form-spacing;
  position: relative;

  [type="checkbox"],
  [type="radio"] {
    appearance: none;
    border: 0 !important;
    box-shadow: none;
    height: rem-calc(16);
    left: 0;
    outline: none;
    position: absolute;
    top: 50%;
    width: rem-calc(16);
    z-index: 5; // Just above labels

    &:disabled {
      background: none;
      opacity: 0.5;
    }
  }

  label {
    align-items: center;
    display: flex;

    // We want regular input labels to be bold,
    // but not the ones aligned next to toggles
    font-weight: normal;
    justify-content: flex-start;
    position: relative;

    &::before,
    &::after {
      pointer-events: none;
    }

    &::before {
      content: " ";
      display: flex;
      height: rem-calc(16);
      margin-right: $form-spacing;
      min-height: rem-calc(16);
      min-width: rem-calc(16);
      width: rem-calc(16);
    }
  }
}

.checkbox {
  label {
    &::before {
      background: url("images/checkbox.svg");
    }

    &:hover::before {
      background: url("images/checkbox-hover.svg");
    }
  }

  input:focus + label::before {
    background: url("images/checkbox-hover.svg");
  }

  input:checked + label::before {
    background: url("images/checkbox-checked.svg");
  }

  input:disabled + label::before {
    cursor: not-allowed;
  }
}

.radio {
  label {
    &::before {
      background: url("images/radio.svg");
    }

    &:hover::before {
      background: url("images/radio-hover.svg");
    }
  }

  input:focus + label::before {
    background: url("images/radio-hover.svg");
  }

  input:checked + label::before {
    background: url("images/radio-checked.svg");
  }

  input:disabled + label::before {
    cursor: not-allowed;
  }
}
