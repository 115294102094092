.modal-form {
  border-radius: $global-radius * 2;
  box-shadow: 0 8px 22px 0 $black;
  max-width: rem-calc(514);

  &__close-button {
    cursor: pointer;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    h2 {
      margin-bottom: 0;
      padding: 0.5rem 0;
    }

    &--collaborators {
      margin-bottom: 1.6rem;

      h2 {
        padding-bottom: 0;
      }
    }
  }

  &__metadata {
    color: get-color(medium);
    font-size: rem-calc(13);
  }

  &__subheading {
    color: $black;
    margin-top: 2rem;
  }

  &__fields {
    margin-bottom: $global-margin;

    &--collaborators {
      border: 1px solid get-color(light);
      border-radius: $global-radius;
    }
  }

  &__buttons {
    margin-top: $global-margin * 2;

    &--cancel {
      margin-right: $global-margin;
    }
  }
}
