input,
select,
textarea {
  &:invalid,
  &.invalid {
    border: solid 2px get-color(alert);
  }
}

.form-error {
  background: get-color(alert-x-light);
  border-radius: $global-radius;
  padding: 0.25rem;
  text-align: right;
}
