@import "icons";

.folder {
  border-radius: 11px;
  padding: rem-calc(20) 0;
  position: relative;

  &:hover {
    background: get-color(royal-x-light);

    .folder__icon {
      @include icon-fill(get-color(royal));
    }

    .folder__title {
      color: get-color(royal);
      text-decoration: underline;
    }
  }

  &__details {
    color: get-color(medium);
  }

  &__title {
    font-weight: bold;
  }

  &__icon {
    &--folder {
      margin-left: rem-calc(24);
      margin-right: rem-calc(12);
    }

    &--options {
      position: absolute;
      right: 21px;
    }
  }

  &__menu {
    @include menu-base;

    > li > a {
      padding: 0.5rem;

      span {
        margin-left: 8px;
      }
    }
  }

  &__menu-item {
    @include menu-item;
    @include menu-sub-item;
  }

  &__dropdown-pane {
    @include dropdown-container;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
    max-width: rem-calc(161);
    padding: 0;
  }
}
