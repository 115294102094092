.hero-unit {
  min-height: 360px;
  padding: 0 2rem;
  z-index: auto;

  @include breakpoint(large) {
    background-color: get-color(primary-light);
    border-radius: 0 0 58px;
    margin-right: 2rem;
    padding: 0 rem-calc(132);
  }

  &__text {
    margin-bottom: 0;
  }

  &__container {
    margin-top: 1rem;
    z-index: 1;

    @include breakpoint(large) {
      padding: 4rem 0 2rem;
    }
  }

  &__search {
    > .search-bar > label {
      margin: 0;
    }

    > .search-bar > label > input {
      background: $white;
      background-image: url("images/search.svg");
      background-position: calc(100% - 1rem);
      background-repeat: no-repeat;
      margin: 0 !important;
      padding-right: 2rem;
      transition:
        box-shadow,
        background-color linear 0.5s;
    }
  }

  &__browse-link {
    @include flex;
    @include flex-align(justify, middle);
    @include breakpoint(medium) {
      border-radius: $global-radius;
    }
    @include breakpoint(large) {
      margin: 0 0 0 1rem;
    }

    background: $white;
    border-radius: 5000px;
    height: 39px;
    margin: 1rem 0;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    & > a,
    & > li > a {
      color: get-color(medium) !important;
      font-weight: normal !important;
      padding: 0 1.5rem 0 0 !important;
      text-decoration: none;
      width: auto;
    }

    .is-dropdown-submenu {
      z-index: 3;
    }
  }
}
