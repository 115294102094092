$icon-sizes: (
  tiny: rem-calc(10),
  default: rem-calc(16),
  mid: rem-calc(20),
  large: rem-calc(64)
);

$icon-palette: map-merge(
  $foundation-palette,
  (
    "white": $white,
    "black": $black
  )
);

@mixin icon-fill($color: $black) {
  // Icons are almost always drawn with paths rather than geometric shapes
  // If we target everything here (*), we usually pick up something we didn't
  // want, like a containing <rect>
  &,
  & path {
    @if (map-has-key($icon-palette, $color)) {
      fill: map-get($icon-palette, $color);
    } @else {
      fill: $color;
    }
  }
}

@mixin icon-stroke($color: $black) {
  // Icons are almost always drawn with paths rather than geometric shapes
  // If we target everything here (*), we usually pick up something we didn't
  // want, like a containing <rect>
  &,
  & path {
    @if (map-has-key($icon-palette, $color)) {
      stroke: map-get($icon-palette, $color);
    } @else {
      stroke: $color;
    }
  }
}

@mixin icon-size($size: default) {
  height: map-get($icon-sizes, $size);
  vertical-align: text-bottom;
  width: map-get($icon-sizes, $size);
}

.icon {
  @include icon-fill;
  @include icon-size;

  @each $name, $color in $icon-palette {
    &--#{$name} {
      @include icon-fill($color);
    }
    &--stroke-#{$name} {
      @include icon-stroke($color);
    }
  }

  @each $name, $size in $icon-sizes {
    &--#{$name} {
      @include icon-size($name);
    }
  }

  &--inline {
    margin-left: 0.5rem;
    vertical-align: middle;
  }

  &--hollow {
    @include icon-fill(transparent);
  }
}
