.admin-icons {
  text-align: right;

  .button {
    padding: 0.5em;

    &:hover {
      svg {
        @include icon-fill(get-color(royal));
      }
    }
  }
}
