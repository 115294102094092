.home-main {
  padding: 0 auto;

  &__container {
    margin: 4rem 0;
    padding: 0 1rem;
  }

  &__icon {
    margin-bottom: 8px;
    position: relative;
  }

  &__icon-shadow {
    left: 12px;
    position: absolute;
    z-index: -1;
  }

  &__signs {
    margin: 0 0 5rem;
  }
}
