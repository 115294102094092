.table-row {
  // These class names need to match the sign states in order to work
  &--published {
    background-color: get-color(extra-light);
  }

  &--archived {
    background-color: get-color(alert-x-light);
  }

  &__link {
    color: get-color(royal);
    text-decoration: underline;
  }
}
