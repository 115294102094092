.no-js {
  [data-responsive-menu] {
    visibility: hidden;
  }
}

.show {
  display: block !important;
}

legend {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend label;
}

.vertical-flip {
  transform: rotate(-180deg);
}
