body,
html {
  min-height: 100vh;
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  flex-shrink: 1;
}

main {
  @include xy-grid-container;
  flex: 1 0 auto;
  width: 100%;
}

footer {
  flex-shrink: 1;
}

.row {
  @include xy-grid;

  &--spaced {
    margin-bottom: $global-margin;
  }
}

.block {
  @include xy-cell;

  &--9 {
    @include breakpoint(medium) {
      @include xy-cell(9);
    }
  }

  &--8 {
    @include breakpoint(medium) {
      @include xy-cell(8);
    }
  }

  &--6 {
    @include breakpoint(medium) {
      @include xy-cell(6);
    }
  }

  &--4 {
    @include breakpoint(medium) {
      @include xy-cell(4);
    }
  }

  &--3 {
    @include breakpoint(medium) {
      @include xy-cell(3);
    }
  }
}
