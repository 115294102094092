.file-preview {
  @include media-object-container;
  margin: 1rem 0;

  .file-preview__media,
  .file-preview__metadata {
    @include media-object-section;
  }

  .file-preview__media {
    // Take up a quarter of the width, but no less than a minimum for visibility
    border-radius: $global-radius;
    min-width: 10rem;
    overflow: hidden;
    width: 25%;
  }

  .file-preview__metadata {
    align-self: center;
    flex: 1;

    // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
    &__key {
      font-size: 1rem;
      font-weight: bold;
      line-height: rem-calc(16); // For icon alignment
      overflow-wrap: break-word;
      vertical-align: text-top;
      word-break: break-word;
      word-wrap: break-word;
    }
  }
}
