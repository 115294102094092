.label {
  align-items: center;
  display: flex;
  height: 30px;
  margin: 0.5rem 1rem;
  padding: 0;
  text-transform: uppercase;

  &__icon-wrapper {
    align-items: center;
    background: get-color(primary);
    border-bottom-left-radius: $global-radius;
    border-top-left-radius: $global-radius;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 0.5rem;
    width: 30px;
  }
}
