$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$social-brand-youtube: #b00;
$social-brand-instagram: #125688;
$social-brand-pinterest: #cb2027;
$social-brand-google-plus: #dd4b39;
$social-brand-github: #000;
$social-brand-tumblr: #32506d;

.fa {
  font-weight: 900;
}

.social-media {
  .navigation__wrapper & {
    margin-top: 2 * $global-margin;

    @include breakpoint(large) {
      display: none;
    }
  }

  &__list {
    @include menu-base;

    a {
      padding: 0.7rem 0 0.7rem 1rem;
    }
  }

  &__link {
    margin-right: 0.25rem;

    i {
      color: $white;
      margin-right: 0.25rem;

      .navigation__wrapper & {
        color: $primary-color;
      }
    }
  }
}
