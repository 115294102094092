.list {
  background: $white;
  border-radius: rem-calc(8);
  margin-bottom: $global-margin * 2;
  margin-top: $global-margin;

  &__section {
    padding: 1.7rem;

    &--sign-cards {
      justify-content: space-around;
    }

    &--topics {
      column-count: auto;
      column-width: 150px;
      padding-bottom: 2.4rem;

      div {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--admin {
    padding: 0;
  }

  &--compact {
    margin: 0;
    padding: 0;

    .list__item {
      padding: 0.5rem 0;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--admin {
      padding: $global-padding * 2;
    }
  }

  &__description {
    color: $black;
    font-size: 15px;
    margin-top: $global-margin / 2;

    &--metadata {
      color: get-color(medium);
      font-size: rem-calc(13);
    }

    .bullet {
      color: get-color(medium);
    }
  }

  .button {
    margin: 0;
  }

  &__items {
    justify-content: space-between;
  }

  &__item {
    border-radius: 11px;
    padding: $global-padding 0;
    position: relative;

    &--interactive {
      &:hover {
        background: get-color(royal-x-light);

        .list__item__icon {
          @include icon-fill(get-color(royal));
        }

        .list__item__title {
          color: get-color(royal);
          text-decoration: underline;
        }
      }
    }

    &__details {
      color: get-color(medium);

      a {
        color: get-color(medium);
        font-weight: normal;
      }
    }

    &__title {
      font-weight: bold;

      &--filename {
        overflow-wrap: break-word;
      }
    }

    &__icon {
      &--folder {
        margin-left: rem-calc(24);
        margin-right: rem-calc(12);
      }

      &--options {
        position: absolute;
        right: 21px;
      }
    }

    &--compact {
      align-items: center;
      display: flex;
      & > span {
        flex: 1;
      }
    }
  }

  &__item-menu {
    @include menu-base;

    > li > a {
      padding: 0.5rem;

      span {
        margin-left: 8px;
      }
    }

    &--collab {
      max-height: rem-calc(243);
      overflow-y: scroll;
      position: relative;
    }

    &__menu-item {
      @include menu-item;
      @include menu-sub-item;
    }

    &__dropdown-pane {
      @include dropdown-container;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
      padding: 0;
      z-index: 101;

      &--admin {
        max-width: rem-calc(224);
      }

      &--folders {
        max-width: rem-calc(161);
      }
    }
  }

  &__divider {
    background-color: get-color(primary-x-light);
    border: 0;
    clear: both;
    display: block;
    height: 1px;
    margin: 0;
    width: 100%;

    &--vertical {
      background-color: get-color(medium);
      height: 24px;
      margin: 0 $global-margin / 2;
      width: 1px;
    }

    &--dark {
      background-color: get-color(light);
    }
  }
}
