$sign-card-width: rem-calc(258);

.sign-grid {
  display: grid;

  // Sass-lint doesn't know about the 'gap' property?
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, $sign-card-width);
  margin: 1rem auto;

  &__sort {
    min-width: rem-calc(200);
  }
}
