body {
  &.no-js {
    .hide-if-no-js {
      display: none !important;
    }

    .show-if-no-js {
      display: block !important;
    }
  }

  &:not(.no-js) {
    .show-if-no-js {
      display: none !important;
    }
  }
}
