.sidebar {
  background: $white;
  max-width: 240px;
  z-index: 100;

  li {
    padding: 0;
  }

  .menu {
    border-bottom: solid 1px $extra-light-gray;
    padding: 1rem 0.5rem 1rem 0;
  }

  .menu-text {
    color: get-color(primary);
    font-size: 13px;

    &--home {
      span {
        font-weight: bold;
      }
    }
  }

  .menu > li > a {
    flex-direction: row;
    font-weight: normal;

    img + span,
    svg + span {
      color: $black;
      flex: 1;
      margin-left: 1rem;
    }

    &:hover {
      @include icon-fill(get-color(royal));

      border-bottom-right-radius: rem-calc(24);
      border-top-right-radius: rem-calc(24);
      font-weight: bold !important;
    }
  }

  .is-active {
    @include icon-fill(get-color(royal));

    a {
      border-bottom-right-radius: rem-calc(24);
      border-top-right-radius: rem-calc(24);
      font-weight: bold !important;
    }
  }

  // For small screens
  &--off-canvas {
    height: auto;
    margin-top: 120px;
    max-width: 100vw;
    min-height: 100%;
    overflow-y: scroll;
    padding-top: 2rem; // Make space for the close button
    width: 100%;
  }

  // For medium screens and up
  &--inside-grid {
    min-height: 900px;

    @include breakpoint(small only) {
      display: none;
    }

    @include breakpoint(medium only) {
      .menu > li > a {
        flex-direction: column;
        font-size: 80%;
        padding: 1rem 0 1rem 0.5rem;

        img + span,
        svg + span {
          margin: 1rem auto auto;
        }
      }
    }
  }
}
